<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col v-if="!loading.loading" cols="12">
        <FilterDialog :consultores="consultores" @filter="filter" />
      </v-col>
    </v-row>

    <base-loading v-if="loading.loading" />
    <v-carousel
      v-else-if="slides.length"
      id="carousel"
      :cycle="false"
      width="100%"
      :height="resize ? '100%' : '800px'"
      hide-delimiter-background
      show-arrows-on-hover
      :continuous="false"
    >
      <v-carousel-item v-for="(slide, i) in slides" :key="i" fade>
        <v-sheet
          v-if="slide"
          :key="slide.CodConsultorTecnico"
          ref="carousel"
          class="gradient-sheet align-center justify-center text-center"
          height="100%"
        >
          <h2 class="text-h2 font-weight-medium user-name">
            {{ slide.name }}
          </h2>
          <v-row class="fill-height align-center">
            <v-col cols="12" md="5" class="d-flex">
              <div class="content-consultant">
                <div>
                  <img
                    v-if="slide.avatar"
                    class="image-consultant"
                    :src="slide.avatar"
                    :alt="slide.name"
                  />
                  <v-icon
                    v-else
                    color="white"
                    size="350px"
                    class="mdi mdi-account"
                  ></v-icon>
                </div>
                <div class="info-card text-center">
                  <p class="text-h3">
                    {{ slide.name }},
                    {{ slide.year }}
                    Anos - {{ slide?.Formacao || 'Não informado' }}
                  </p>
                </div>
              </div>
            </v-col>

            <v-col cols="12" md="7" class="text-center">
              <h3 class="text-h3">Informações gerais</h3>
              <v-row cols="4">
                <v-col cols="8" class="d-inline-flex">
                  <div class="d-flex align-center">
                    <div class="text-h4">Dados cadastrais:</div>
                  </div>
                </v-col>

                <v-col>
                  <div class="d-flex">
                    <router-link
                      :to="{
                        path: `/${$user.get().role}/consultor/form`,
                        query: { id: slide.CodConsultorTecnico },
                      }"
                      target="_blank"
                    >
                      <v-btn class="mx-2 transparent" fab small>
                        <v-icon> mdi-circle-edit-outline </v-icon>
                      </v-btn>
                    </router-link>
                    <RegistrationData :user="slide" />
                  </div>
                </v-col>

                <v-col cols="8" class="d-inline-flex">
                  <div class="d-flex align-center">
                    <div class="text-h4">Base/Reagião de atuação:</div>
                    <div class="text-h4 font-weight-bold ml-1">
                      {{
                        `${
                          slide.pessoa.endereco?.cidade?.Nome ?? 'Não informado'
                        }, ${
                          slide.pessoa.endereco?.cidade?.estado.Sigla ??
                          'Não informado'
                        }` || 'Não disponível '
                      }}
                    </div>
                  </div>
                </v-col>

                <v-col>
                  <div class="d-flex align-center">
                    <router-link
                      :to="{
                        path: `/${
                          $user.get().role
                        }/relatorios/farmDistribution`,
                        query: { userId: slide.CodConsultorTecnico },
                      }"
                      target="_blank"
                    >
                      <v-btn class="mx-2 transparent" fab small>
                        <v-icon> mdi-eye-outline </v-icon>
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>

                <v-col cols="8" class="">
                  <div class="d-flex">
                    <div class="text-h4">Empresas vinculadas:</div>
                    <div class="text-h4 font-weight-bold ml-1">
                      {{ slide.entreprise }}
                    </div>
                  </div>
                </v-col>

                <v-col>
                  <div class="d-flex">
                    <LinkedCompanies
                      :user="slide"
                      :agroindustry-logos="agrindustriaLogos"
                    />
                  </div>
                </v-col>

                <v-col cols="8" class="">
                  <div class="d-flex">
                    <div class="text-h4">
                      Visitas realizadas/Previstas último mês:
                    </div>
                    <div class="text-h4 font-weight-bold ml-1">
                      {{
                        slide.monthRealizedVisits + '/' + slide.monthTotalVisits
                      }}
                    </div>
                  </div>
                </v-col>

                <v-col>
                  <div class="d-flex">
                    <router-link
                      :to="{
                        path: `/${$user.get().role}/schedules`,
                        query: { consultor: slide.CodConsultorTecnico },
                      }"
                      target="_blank"
                    >
                      <v-btn class="mx-2 transparent" fab small>
                        <v-icon> mdi-eye-outline </v-icon>
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>

                <v-col cols="8" class="">
                  <div class="d-flex">
                    <div class="text-h4">
                      Custo acumulado por visita/Custo por visita mensal:
                    </div>
                    <div class="text-h4 font-weight-bold ml-1">
                      R$ {{ currencyFormatter(slide.monthTotalExpenses) }}/{{
                        currencyFormatter(slide?.meta?.meta, 'R$')
                      }}
                    </div>
                  </div>
                </v-col>

                <v-col>
                  <div class="d-flex">
                    <router-link
                      :to="{
                        path: `/${$user.get().role}/financeiros`,
                        query: { userId: slide.CodConsultorTecnico, tab: 1 },
                      }"
                      target="_blank"
                    >
                      <v-btn class="mx-2 transparent" fab small>
                        <v-icon> mdi-eye-outline </v-icon>
                      </v-btn>
                    </router-link>
                  </div>
                </v-col>

                <v-col cols="8" class="">
                  <div class="d-flex align-center">
                    <div class="text-h4">Desempenho geral:</div>
                    <v-rating
                      readonly
                      background-color="orange lighten-3"
                      color="orange"
                      half-increments
                      hover
                      length="5"
                      size="25"
                      :value="slide?.avaliation?.geral ?? 0"
                    ></v-rating>
                  </div>
                </v-col>

                <v-col v-if="slide.avaliations">
                  <div class="d-flex">
                    <AvaliationGeneral :user="slide" />
                  </div>
                </v-col>

                <v-col cols="8" class="">
                  <div class="d-flex align-center">
                    <div class="text-h4">Evolução técnica:</div>
                    <v-rating
                      readonly
                      background-color="orange lighten-3"
                      color="orange"
                      half-increments
                      hover
                      length="5"
                      size="25"
                      :value="slide?.avaliation?.evolucao_tecnica ?? 0"
                    ></v-rating>
                  </div>
                </v-col>

                <v-col v-if="slide.avaliations">
                  <div class="d-flex">
                    <AvaliationTechnique :user="slide" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-btn
            v-if="slide"
            color="gray"
            class="fullscreen"
            icon
            @click="openFullscreen"
          >
            <v-icon>{{
              resize ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
            }}</v-icon>
          </v-btn>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-row
      v-else-if="!slides.length && !loading.loading"
      justify="center"
      align="center"
      class="not-found-item"
    >
      <v-col cols="12" class="text-center">
        <v-icon size="64">mdi-alert-circle-outline</v-icon>
        <div class="headline">
          Selecione um consultor para visualizar o dashboard
        </div>
        <div class="caption">
          Selecione um consultor valido para visualizar o dashboard
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AvaliationGeneral from './AvaliationGeneral.vue'
import AvaliationTechnique from './AvaliationTechnique.vue'
import FilterDialog from './FilterDialog.vue'
import LinkedCompanies from './LinkedCompanies.vue'
import RegistrationData from './RegistrationData.vue'

import moment from 'moment'
import ConsultantDashboardService from '../../../services/api/consultantDashboard'
import { currencyFormatter } from '../../../utils/formatter'

export default {
  name: 'Dashboard',

  components: {
    AvaliationGeneral,
    AvaliationTechnique,
    LinkedCompanies,
    RegistrationData,
    FilterDialog,
  },

  data() {
    return {
      slides: [],
      consultores: [],

      agrindustriaLogos: {},

      geocoder: null,

      loading: {
        loading: false,
      },

      resize: false,

      carousel: null,
    }
  },

  computed: {},

  created() {
    this.search()
  },

  mounted() {
    document.addEventListener('keydown', e => {
      setTimeout(() => {
        const carouselElement = document?.getElementById('carousel')
        if (!carouselElement) return

        const clickCarouselButton = buttonSelector => {
          const button = carouselElement.querySelector(buttonSelector)
          if (!button) return
          button.click()
        }

        if (e.key === 'ArrowLeft') {
          clickCarouselButton('.v-window__prev .v-btn')
        } else if (e.key === 'ArrowRight') {
          clickCarouselButton('.v-window__next .v-btn')
        }
      }, 400)
    })

    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        this.resize = false
      }
    })
  },

  methods: {
    moment,
    currencyFormatter,
    async search() {
      try {
        this.loading.loading = true
        const result = await ConsultantDashboardService.index()

        if (!result.consultores.length) {
          return this.Toast().fire({
            icon: 'warning',
            title: 'Não ah consultores cadastrados',
          })
        }

        if (Array.isArray(result.agroindustryLogos))
          this.agrindustriaLogos = result.agroindustryLogos

        if (Object.keys(result.agroindustryLogos).length) {
          this.agrindustriaLogos = Object.keys(result.agroindustryLogos).map(
            key => {
              return {
                CodAgroindustria:
                  result.agroindustryLogos[key]['CodAgroindustria'],
                logotipo: result.agroindustryLogos[key]['logotipo'],
              }
            },
          )
        }

        const slides = result.consultores.map(consultant => {
          let entrepise = consultant.fazendas.map(fazenda => {
            return fazenda?.agroindustria_ativa?.CodAgroindustria
          })
          entrepise = entrepise.filter(item => item)

          return {
            ...consultant,
            monthTotalVisits: consultant.monthTotalVisits,
            year: this.getYear(
              consultant.pessoa?.pessoafisica?.DataNascimento || 0,
            ),
            entreprise: entrepise.length ? [...new Set(entrepise)].length : 0,
          }
        })

        this.all = slides
        this.slides = slides

        this.consultores = result.consultores
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar dados dos consultores',
        })
      } finally {
        this.loading.loading = false
      }
    },

    openFullscreen() {
      this.resize = !this.resize

      if (!this.resize) return this.closeFullscreen()

      const carousel = document.querySelector('#carousel')

      if (carousel.requestFullscreen) {
        carousel.requestFullscreen()
      } else if (carousel.webkitRequestFullscreen) {
        carousel.webkitRequestFullscreen()
      } else if (carousel.msRequestFullscreen) {
        carousel.msRequestFullscreen()
      }
    },

    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },

    validateCoordinates(lat, lng) {
      if (!lat || !lng) return false

      return (
        isFinite(lat) &&
        Math.abs(lat) <= 90 &&
        isFinite(lng) &&
        Math.abs(lng) <= 180
      )
    },

    getYear(date) {
      const dateOfBirth = moment(date)

      if (!dateOfBirth.isValid()) return 0

      return moment().diff(dateOfBirth, 'years')
    },

    filter(consultoresIds) {
      if (!consultoresIds.length) return (this.slides = this.all)

      this.slides = this.all.filter(consultor =>
        consultoresIds.includes(consultor.CodConsultorTecnico),
      )
    },
  },
}
</script>

<style>
.gradient-sheet {
  background: linear-gradient(to bottom right, #0d133d, #243189);
  padding: 20px;
  border-radius: 8px;
  color: white;
}

.info-card {
  border: 5px solid #32bd50;
  border-radius: 1rem;
  padding: 0.4rem;
  max-width: 300px;
  color: white;
}

.theme--light.v-sheet {
  color: white;
}

div.v-carousel__controls {
  display: none;
}

.v-window__container > .v-carousel__controls {
  display: none;
}

.v-icon.notranslate.mdi.mdi-circle.theme--dark {
  display: none;
}

.fullscreen {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.user-name {
  margin-bottom: 1rem;
  font-size: 3rem;
}

.content-consultant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;

  margin: 0 auto;

  margin-bottom: 3rem;
}

@media (max-width: 2560px) {
  .image-consultant {
    width: 500px;
    height: 550px;
    padding-bottom: 0.5rem;
  }
}

@media (max-width: 1920px) {
  .image-consultant {
    width: 450px;
    height: 500px;
    padding-bottom: 0.5rem;
  }

  .text-h3 {
    font-size: 4.5vw;
  }

  .text-h4 {
    font-size: 2.5vw;
  }
}

@media (max-width: 1366px) {
  .image-consultant {
    width: 300px;
    height: 350px;
  }
}
</style>

<style scoped>
.not-found-item {
  height: 300px; /* Adjust the height as needed */
  margin-top: 50px;
}
</style>
